import cx from 'classnames';

type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const HeadingTheme: { [key in HeadingType]: string } = {
  h1: 'text-5xl',
  h2: 'text-4xl',
  h3: 'text-3xl',
  h4: 'text-2xl',
  h5: 'text-xl',
  h6: 'text-lg',
};

interface HeadingProps {
  variant: HeadingType;
  stylesOf?: HeadingType;
  className?: string;
}

export const Heading: React.FC<HeadingProps> = ({ children, variant, className, stylesOf, ...rest }) => {
  const Heading = variant;
  const headingStyles = stylesOf ? HeadingTheme[stylesOf] : HeadingTheme[variant];

  return (
    <Heading {...rest} className={cx('font-Merriweather font-normal', headingStyles, className)}>
      {children}
    </Heading>
  );
};
